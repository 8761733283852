<template>
  <page-main
    title="User Options"
    :aside="[]"
    nextText=""
    buttonTabIndex="-1"
    eventName="nav"
    noBottom
  >
    <v-container>
      <v-overlay v-model="loading">
        <v-progress-circular
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
      <v-row  v-if="canConfirmEmail">
        <v-col cols="12" class="r-col">
          <div class="text-h6 ml-n2">Change Your Password</div>
          <v-form ref="form" v-model="valid" @submit.prevent="changePwd()">
            <div class="pt-3 max-w-600">
              <v-text-field
                v-model="oldPassword"
                label="Current Password"
                dense
                class="pb-1"
                :append-icon="showOldPwd ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showOldPwd ? 'text' : 'password'"
                @click:append="showOldPwd = !showOldPwd"
              ></v-text-field>
              <v-text-field
                v-model="newPassword"
                label="New Password"
                dense
                class="pb-1"
                :rules="pwdRules"
                :append-icon="showNewPwd ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showNewPwd ? 'text' : 'password'"
                @click:append="showNewPwd = !showNewPwd"
              ></v-text-field>
              <div class="d-flex justify-center" v-if="successText !== ''">
                <p class="text-body-1 font-weight-medium">
                  {{ successText }}
                </p>
              </div>
              <div class="d-flex justify-center" v-if="errorText !== ''">
                <p class="error--text text-body-1 font-weight-medium">
                  {{ errorText }}
                </p>
              </div>
              <v-btn type="submit" :disabled="loading" outlined>
                Change Password
              </v-btn>
            </div>
          </v-form>
        </v-col>
      </v-row>
      <v-row class="pt-5" v-if="canConfirmEmail">
        <v-col cols="12" class="r-col">
          <div class="text-h6 ml-n2">Confirm Your Email</div>
          <div class="text-body-1 text--secondary" v-if="emailConfirmed">
            Your Email Address is already confirmed.
          </div>
          <div class="text-body-1 text--secondary" v-else>
            You need to confirm your Email Address. Click the link in the email
            we send you to confirm your email address.
          </div>
          <div class="d-flex justify-start" v-if="emailErrorText !== ''">
            <p class="error--text text-body-1 font-weight-medium">
              {{ emailErrorText }}
            </p>
          </div>
          <div class="pt-3" v-if="!emailConfirmed">
            <v-btn
              outlined
              @click="sendConfirmEmail()"
              :disabled="!confirmEmailEnabled"
              :loading="confirmEmailLoading"
              >{{ confirmEmailButtonText }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
      <v-row class="pt-5">
        <v-col cols="12" class="r-col">
          <div class="text-h6 ml-n2">Remove All Resume Data</div>
          <div class="text-body-1 text--secondary">
            This will remove all of your resume data. You will not be able to
            recover it.
          </div>
          <div class="pt-3">
            <v-btn outlined v-if="removeClick == 0" @click="removeFirst()"
              >Remove all Data</v-btn
            >
            <v-btn
              solid
              color="error"
              v-if="removeClick == 1"
              @click="removeFinal()"
              :disabled="!canRemove"
              :loading="loadingRemove"
            >
              Click again to remove data
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row class="pt-5">
        <v-col cols="12" class="r-col">
          <div class="text-h6 ml-n2">Delete Your Account</div>
          <div class="text-body-1 text--secondary">
            This will remove all resume data, as well as remove your account and
            log you out. You will not be able to recover any information.
          </div>
          <div class="pt-3">
            <v-btn outlined v-if="deleteClick == 0" @click="deleteFirst()"
              >Delete Account</v-btn
            >
            <v-btn
              solid
              color="error"
              v-if="deleteClick == 1"
              @click="deleteFinal()"
              :disabled="!canDelete"
              :loading="loadingDelete"
            >
              Click again to delete account
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
// todo: Split these out to seperate components for re-useability!
import * as optionsApi from "../api/OptionsApi";
import { mapMutations } from "vuex";
import { mapFields } from "vuex-map-fields";
import * as login from "../api/LoginApi";

export default {
  name: "Options",
  data: () => ({
    loading: false,
    removeClick: 0,
    canRemove: false,
    loadingRemove: false,
    deleteClick: 0,
    canDelete: false,
    loadingDelete: false,
    oldPassword: "",
    newPassword: "",
    showOldPwd: false,
    showNewPwd: false,
    pwdRules: [
      (v) => !!v || "Required.",
      (v) => v.length >= 12 || "Min 12 characters",
      (v) =>
        /.*[A-Z].*/.test(v) || "Must contain at least one uppercase letter",
      (v) =>
        /.*[a-z].*/.test(v) || "Must contain at least one lowercase letter",
      (v) => /.*[0-9].*/.test(v) || "Must contain at least one number",
      (v) =>
        /.*[!@#$%^&*()\-_+=].*/.test(v) ||
        "Must contain at least one special character",
    ],
    errorText: "",
    successText: "",
    valid: true,
    emailErrorText: "",
    confirmEmailButtonText: "Re-Send Confirmation Email",
    confirmEmailEnabled: true,
    confirmEmailLoading: false,
  }),
  components: {},
  mounted() {
    console.log(this.authType);
  },
  methods: {
    ...mapMutations(["removeResume"]),
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    removeFirst() {
      this.removeClick = 1;
      this.loadingRemove = true;
      setTimeout(() => {
        this.canRemove = true;
        this.loadingRemove = false;
      }, 1000);
    },
    async removeFinal() {
      this.loadingRemove = true;
      this.canRemove = false;
      await optionsApi.PostRemoveAllResumes();
      this.removeResume();
      this.nav("/Resume");
    },
    deleteFirst() {
      this.deleteClick = 1;
      this.loadingDelete = true;
      setTimeout(() => {
        this.canDelete = true;
        this.loadingDelete = false;
      }, 1250);
    },
    async deleteFinal() {
      this.loadingDelete = true;
      this.canDelete = false;
      await optionsApi.PostRemoveAccount();
      this.removeResume();
      this.nav("/Logout");
    },
    async changePwd() {
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }

      try {
        this.errorText = "";
        this.successText = "";
        this.loading = true;

        const changeResponse = await login.ChangePassword(
          this.oldPassword,
          this.newPassword
        );

        this.$refs.form.reset();
        this.oldPassword = "";
        this.newPassword = "";

        if (!changeResponse.success) {
          this.errorText = changeResponse.displayText;
        } else {
          this.successText = "Password changed successfully!";
        }
      } finally {
        this.loading = false;
      }
    },
    async sendConfirmEmail() {
      this.confirmEmailLoading = true;
      this.confirmEmailEnabled = false;

      const confirmEmailResp = await login.SendConfirmEmail();
      if (!confirmEmailResp.success) {
        this.emailErrorText = "Unable to send a confirmation email.";
        this.confirmEmailEnabled = true;
      } else {
        this.confirmEmailButtonText = "Confirmation Email Sent";
        this.confirmEmailEnabled = false;
      }
      this.confirmEmailLoading = false;
    },
  },
  computed: {
    ...mapFields("auth", {
      emailConfirmed: "emailConfirmed",
      authType: "auth.authType",
    }),
    canConfirmEmail()
    {
      return this.authType === "resumation"; // only need to confirm emails for the managed login type. 
    }
  },
};
</script>

<style scoped>
.max-w-600 {
  max-width: 600px;
}
</style>